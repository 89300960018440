<template>
  <MainLanding/>
</template>

<script>
import MainLanding from "@/MainLanding";
export default {
  name: 'App',
  components: {MainLanding},
}
</script>
