import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarOptions from './configs/quasar-options'
import i18n from '@/locales/i18n'

createApp(App)
  .use(Quasar, quasarOptions as any)
  .use(i18n)
  .mount('#app')
