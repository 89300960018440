
import i18n from "@/locales/i18n";
import {defineComponent} from "vue";
import axios from 'axios'
import { omit } from 'lodash'

export default defineComponent({
  name: "MainLanding",
  data: () => {
    return {
      langLabel: 'EN',
    }
  },
  async beforeMount() {
    const params = Object.fromEntries(new URL(window.location.href).searchParams)
    if (
      params &&
      Object.keys(params)?.length &&
      !localStorage.getItem('referrerParams')
    ) {
      localStorage.setItem('referrerParams', JSON.stringify({
        referrer: document.referrer || null,
        ...omit(params, 'lang'),
      }))
    }
    let queryLang = params?.lang as any
    if (queryLang) {
      if (
        !['RU', 'UA', 'EN', 'PL', 'DE', 'FR', 'ES'].includes(
          queryLang.toUpperCase(),
        )
      ) {
        queryLang = 'EN'
      }
      await this.changeLang(queryLang.toLowerCase())
      return
    }
    if (!localStorage.getItem('lang_gmbt')) {
      const lang = (await axios.post('/api/auth/ip-lang')).data
      await this.changeLang(lang.toLowerCase())
    }
    this.langLabel =
      localStorage.getItem('lang_gmbt')?.toUpperCase() || this.langLabel
  },
  async created(): Promise<void> {
    const lang = localStorage.getItem('lang_gmbt') || 'en'
    await this.changeLang(lang)
  },
  methods: {
    async changeLang(lang: string) {
      this.langLabel = lang.toUpperCase()
      i18n.setLocale(lang)
      await axios.post('/api/auth/lang', { lang })
      localStorage.setItem('lang_gmbt', lang)
    },
    getReferrerParamsStr() {
      return new URL(window.location.href).search
    },
  },
})
