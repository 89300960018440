const locales = {
  ua: {
    about_us: 'Про нас',
    advertisers: 'Рекламодавцям',
    publishers: 'Вебмайстрам',
    contacts: 'Контакти',
    login: 'Вхід',
    sign_up: 'Реєстрація',
    get_started: 'GET STARTED',
    scroll_down: 'ГОРТАЙ ВНИЗ',
    about_info_1: 'Data Driven & Performance Oriented підхід дозволяє нам бути першокласним постачальником високоякісного трафіку у ніші iGaming. Завдяки багаторічному досвіду ми заслужили довіру провідних брендів галузі.',
    about_info_2: 'Наша місія - змінити індустрію афіліейт маркетингу, зруйнувавши бар’єр між продуктами та партнерами.',
    about_info_3: 'Наша мета - перевершувати очікування та бути кращим вибором як для рекламодавців, так і для вебмайстрів.',
    advertisers_info: 'Внутрішній медіабаїнг забезпечує надійний високоякісний трафік за допомогою таких джерел, як ASO, PPC, FB, UAC. Аналіз трафіку та постійна робота над покращенням якості - в основі нашої роботи.\n' +
      'Ми закриємо всі ваші потреби в трафіку.',
    publishers_info_1: 'Ретельно відібрані індивідуальні пропозиції для вашого джерела трафіку. Ексклюзивні пропозиції та прозоре спілкування.',
    publishers_info_2: 'Ми дійсно відстоюємо трафік наших партнерів.',
    publishers_info_3: '',
    anastasia: 'Анастасія',
    dmytro: 'Дмитро',
    viktoria: 'Вікторія',
    head_of_advertisers: 'Head of Advertisers',
    head_of_publishers: 'Head of Affiliate',
    head_of_pr: 'Marketing Manager',
    reviews: 'Відгуки',
    voice_our_partners: 'Відгуки наших партнерів',
    bons_review: 'Ми в захваті від співпраці з Gambeat та рекомендуємо їх всім, хто цінує особистий підхід та гнучкі рішення. Команда професіоналів дійсно надає обіцяний трафік високої якості та завжди допомагає нам досягати наших бізнес цілей. Ми сміливо можемо назвати Gambeat одним з найбільш надійних наших партнерів.',
    brazino_review: 'Ми дуже раді працювати з Gambeat. Команда завжди виконує обіцяне, роблячи співпрацю максимально комфортною та приємною.',
    boomerang_review: 'Співпраця з Gambeat.io стала переломним моментом для Boomerang Partners. Їх ультрасучасні технології, надійна команда та інноваційні рішення допомогли суттєво покращити результативність нашої роботи. Партнерство не тільки підвищило нашу ефективність, а й також зіграло свою роль в розвитку нашої партнерської програми. Раді подальшій роботі з Gambeat.io та впевнені що разом досягнемо найвищих результатів в динамічному світі iGaming.',
    seven_stars_review: 'Партнерство з Gambeat зарекомендувало себе як особливо успішне. Підхід до кожного аспекту співпраці є професійним, забезпечуючи високоякісний сервіс та відкритість до ефективної  комунікації. Gambeat вражає своєю цілеспрямованістю та інноваційним підходом до роботи, роблячи їх чудовим партнером для розвитку бізнесу. Їх надійність та високий рівень професіоналізму робить Gambeat безцінним союзником в досягненні спільних цілей.',
    moon_partners_review: '\n' +
      '                        Нам, як продукту, важливо, щоб ураховувалися інтереси не лише вебмастерів, а й рекламодавців. Це одна з небагатьох партнерських програм, де дійсно стежать за якістю трафіку та аналізують його.\n' +
      '\n' +
      '                        Зокрема, хочу відзначити внутрішній медіабайнг Gambeat від якого можна отримати не лише великий обсяг, а й якісних гравців, які зацікавлені у продукті на тривалу перспективу.\n' +
      '\n' +
      '                        Дякую за високий стандарт виконаної роботи та приємне спілкування. Ми цінуємо нашу співпрацю і з нетерпінням чекаємо спільного досягнення ще більших результатів.\n' +
      '                    ',
    pinup_review: 'Раді поділитися позитивним досвідом співпраці з Gambeat! Команда - справжні профі, підходять до кожного завдання з величезним ентузіазмом. Відповідальність та оперативність їхніх менеджерів роблять нашу співпрацю не тільки успішною, а просто космічною! Завдяки їхньому вмінню вирішувати будь-які труднощі, ми завжди досягаємо наших цілей. Спілкування з ними прозоре і доброзичливе, що створює атмосферу повної довіри. Ми отримали класний досвід роботи з командою і з нетерпінням чекаємо майбутніх заливів! 🚀',

  },
  en: {
    about_us: 'About Us',
    advertisers: 'Advertisers',
    publishers: 'Webmasters',
    contacts: 'Contacts',
    login: 'Log in',
    sign_up: 'SIGN UP NOW',
    get_started: 'GET STARTED',
    scroll_down: 'SCROLL DOWN',
    about_info_1: 'Data-driven and performance oriented approach allow us to be the top-tier provider of high-quality online leads in iGaming niche. With many years of experience we earned the trust of the industry leading brands.',
    about_info_2: 'Our mission is to change the affiliate industry by breaking the wall between products and affiliates.',
    about_info_3: 'Our goal is to exceed expectations and ',
    about_info_4: 'to be the preferred choice for advertisers as well as webmasters.',
    advertisers_info: 'In-house mediabuying provide reliable high value traffic with main sources like ASO, PPC, FB, UAC and more. Traffic analysis & quality improvement - at the core of our work. We will solve all your traffic needs.',
    publishers_info_1: 'Carefully selected, custom tailored offers for your traffic source. Exclusive deals and transparent communication.',
    publishers_info_2: 'We know what really matters for you - reliability and accountability.',
    publishers_info_3: 'We stand for your traffic.',
    anastasia: 'Anastasiia',
    dmytro: 'Dmytro',
    viktoria: 'Viktoriya',
    head_of_advertisers: 'Head of Advertisers',
    head_of_publishers: 'Head of Affiliate',
    head_of_pr: 'Marketing Manager',
    reviews: 'Reviews',
    voice_our_partners: 'Voice of Our Partners',
    bons_review: 'We are delighted to work with Gambeat and recommend these guys to everyone who values a personalized approach and flexible solutions. The team of professionals leads the high-quality traffic it promises and always helps us reach our business goals. So we can, with no doubt, call it one of the most reliable partners.',
    brazino_review: 'We are pleased to work with Gambeat. Team fulfill their obligations, making cooperation really comfy and enjoyable.',
    boomerang_review: 'Partnering with gambeat.io has been a game-changer for Boomerang Partners. Their cutting-edge technology, dedicated team, and innovative solutions have elevated our affiliate marketing services. The collaboration has not only enhanced our efficiency but also contributed to the success of our affiliate programs. Excited about the future with gambeat.io, we\'re confident in achieving greater heights in the dynamic world of iGaming.',
    seven_stars_review: 'Our collaboration with Gambeat has proven to be exceptionally successful. They approach every aspect of our joint projects professionally, providing high-quality services and always being ready for effective communication. Gambeat impresses with their dedication and innovative approach, making them excellent partners for business development. Their reliability and high level of professionalism make Gambeat invaluable allies in achieving common goals.',
    moon_partners_review: '\n' +
      '              For us, as for product it’s very important that not only webmasters’ interests are counted but also advertisers’. Gambeat is one of the few affiliate networks that really cares about traffic quality and analyses it.\n' +
      '\n' +
      '              Specifically, I’d like to point out Gambeat’s internal mediabuying team, that delivers not only big volume of leads, but also high value players, that are interested in the product in the long run.\n' +
      '\n' +
      '              Thanks for the high standard of the job done and pleasant communication. We appreciate our cooperation and are looking forward to achieve even greater results.\n' +
      '            ',
    pinup_review: 'We\'re really excited to share the great experience we\'ve had working with Gambeat! Their team is packed with real pros who tackle every task with a ton of enthusiasm. The responsibility and quick action of their managers turn our collaboration into something more than just success – it\'s out of this world! Their knack for solving any challenge ensures that we always meet our objectives. Communicating with them is clear and friendly, fostering an environment of total trust. Working with these guys has been an awesome experience, and we eagerly await future traffic drives! 🚀',
  },
}

export default locales
